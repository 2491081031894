<template>
  <div class="category-card category-card-popup" :style="{ 'background-image': $h.getStyleImage(data?.FileName, 'CATEGORY', null, true) }" @click="onViewCategory(data)">
    <div class="img-overlay"></div>
    <h3 v-if="data?.CategoryName && data?.CategoryName != ''" :class="{ long: data?.CategoryName?.split(' ')?.[0]?.length > 8 }">{{ $h.formatTrimString(data?.CategoryName, 80) }}</h3>
  </div>
</template>

<script>
import { defineComponent, inject, ref } from 'vue'
import { f7 } from "framework7-vue";

export default defineComponent({
  name: 'CategoryCardComponent',
  components: {},
  props: {
    data: Object,
    type: String,
    supplierCode: String
  },
  emits: ['selected'],
  setup(props, { emit }) {
   const $f7router = f7.views.get("#main-view").router;

    const onViewCategory = (data) => {
      if (props?.type && props?.type === 'select') {
        emit('selected', data)
        return
      }

      let queryObj = {}

      if (data?.CategoryCode) {
        queryObj['code'] = data?.CategoryCode
      }

      if (props?.supplierCode) {
        queryObj['SupplierCode'] = props?.supplierCode
      }

      $f7router.navigate({ name: 'categoryListPage', query: queryObj })
    }

    return { onViewCategory }
  }
})
</script>

<style scoped>
.categoryselect-popup .category-container .category-card-popup {
  border-radius: 10px;
  margin: 10px 0;
}
.categoryselect-popup .category-container .category-card-popup h3 {
  font-size: 15px;
  width: 13ch;
  word-spacing: 0px;
}
.categoryselect-popup .category-container .category-card-popup h3.long {
  width: 20ch;
  font-size: 15px;
}
</style>
